<template>
  <div>
    <SEARCH :searchChange='onCallbackSearch' :list="searchArr"></SEARCH>
    <TABS :fileChange='onCallbackFile' :tabChange='onCallbackTab' :list="tabsArr"></TABS>
    <TABLE :selectChange='onCallbackSelect' :buttonChange='onCallbackButton'  :list="tableArr" :data="dataArr"></TABLE>
    <PAGE :pageChange='onCallbackPage' :limitChange='onCallbackLimit' :count="count"></PAGE>
    <add-ques :loadChange='_loadData' ref="addQ"></add-ques>
  </div>
</template>

<script>
import SEARCH from '@/components/search' // 顶部搜索
import TABS from '@/components/tabs' // 横向 button
import TABLE from '@/components/table' // 列表
import PAGE from '@/components/page' // 分页
import addQues from './components/addQues' // 添加考题
import { getExamTestQues, delExamTestQues, getETQSelect, getExamTestQuesDetail, temExamTestQues, expExamTestQues, impExamTestQues } from '@/api/safety'
import { downloadExcel } from '@/utils/util'
export default {
  name: 'SafetyExamTestPaper',
  components: {
    SEARCH,
    TABS,
    TABLE,
    PAGE,
    addQues,
  },
  data() {
    return {
      searchArr: [
        {
          name: '类别',
          type: 'picker',
          options: [],
          key: 'type',
          label: 'name',
          value: 'id',
        },
        {
          name: '题型',
          type: 'picker',
          label: 'name',
          value: 'id',
          options: [
            { id: '', name: '全部' },
            { id: '1', name: '单选题' },
            { id: '2', name: '多选题' },
            { id: '3', name: '判断题' },
          ],
          key: 'quesType',
        },
        {
          name: '题目',
          type: 'input',
          options: [],
          key: 'title',
        },
      ], // 搜索列表
      tabsArr: [
        {
          type: 'primary',
          name: '添加考题',
          func: 'openAddQues',
          isLoading: false,

        },
        {
          type: 'primary',
          name: '导入数据',
          func: '',
          isLoading: false,
          isImport: true,

        },
        {
          type: 'primary',
          name: '下载模板',
          func: 'downloadTem',
          isLoading: false,

        },
        {
          type: 'primary',
          name: '导出数据',
          func: 'downloadExp',
          isLoading: false,

        },
        {
          type: 'danger',
          name: '批量删除',
          func: 'removeExamTestQues',
          isLoading: false,
        },
      ], // 标签列表
      tableArr: [
        {
          prop: 'serial_number',
          label: '编号',
          width: '180',
        },
        {
          prop: 'type2_id',
          label: '类别',
          width: '100',
        },
        {
          prop: 'type',
          label: '题型',
          width: '100',
        },
        {
          prop: 'content',
          label: '题目',
          width: '650',
        },
        {
          prop: 'answer',
          label: '答案',
          width: '180',
        },
        {
          prop: 'score',
          label: '分值',
          width: '180',
        },
        {
          prop: '',
          label: '操作',
          width: '',
          type: 'but',
        },
      ], // 表头列表
      dataArr: [], // 数据列表
      butnArr: [
        {
          type: '',
          name: '编辑',
          func: 'editAddQues',
          isLoading: false,

        },
        {
          type: 'danger',
          name: '删除',
          func: 'removeExamTestQues',
          isLoading: false,

        },
      ], // 数据-操作列表
      count: 0, // 总页数
      formData: {
        page: 1,
        limit: 10,
        type: '',
        type2_id: '',
        content: '',
      },
      ids: '',
      tabsIndex: 0, // tabs索引
      isShowAddQ: false, // 是否打开添加试题
    }
  },
  created() {},
  mounted() {
    this._loadData()
    this._loadSelect()
  },
  methods: {

    // 获取下拉框数据
    _loadSelect: function() {
      getETQSelect().then(res => {
        const { result } = res
        result.unshift({ id: '', name: '全部' })
        this.searchArr[0].options = result
      })
    },

    _loadData: function () {
      this.dataArr = []
      getExamTestQues(this.formData).then((res) => {
        const { result } = res

        this.count = result.count
        result.data.forEach(item => {
          item.buttons = JSON.parse(JSON.stringify(this.butnArr))
        })
        this.dataArr = result.data
      })
    },

    // 添加试卷
    openAddQues: function() {
      this.$refs.addQ.open()
      // this.isShowAddQ = true
      this.loading()
    },

    // 修改试卷
    editAddQues: function() {
      getExamTestQuesDetail(this.ids).then(res => {
        this.$refs.addQ.open(res.result)
      })
    },

    // 下载模板
    downloadTem: function() {
      temExamTestQues().then(res => {
        const { result } = res
        downloadExcel({
          header: result.header,
          data: result.data,
          name: '考题模板',
        })
        this.loading()
      })
    },

    // 导出数据
    downloadExp: function() {
      expExamTestQues().then(res => {
        const { result } = res
        result.list.map(s => {
          s.options && Object.keys(s.options).forEach(i => {
            s[i] = s.options[i]
          })
          return s
        })

        downloadExcel({
          header: result.header,
          data: result.list,
          name: '考题数据',
          title: ['serial_number', 'type', 'content', 'A', 'B', 'C', 'D', 'E', 'F', 'answer', 'score', 'type2_id'],
        })
        this.loading()
      })
    },

    // 删除学习稽查
    removeExamTestQues: function({ id }) {
      if (!this.ids) {
        this.$toast('请选择要删除的考题')
        !id && this.loading()
        return false
      }
      delExamTestQues(this.ids).then(res => {
        this.$toast(res.message)
        this._loadData()
        !id && this.loading()
      })
    },

    // 收到搜索回调
    onCallbackSearch: function(data) {
      const { type, title, quesType } = data
      this.formData.type = quesType
      this.formData.content = title
      this.formData.type2_id = type
      this._loadData()
    },

    // 收到 tab 回调
    onCallbackTab: function({ item, index }) {
      this.loading(index)
      this[item.func]('tabsArr', index)
    },

    // 打开或关闭tabs 按钮loading
    loading: function(index) {
      index && (this.tabsIndex = index)
      this.tabsArr[this.tabsIndex].isLoading = !this.tabsArr[this.tabsIndex].isLoading
    },

    // 上传文件回调
    onCallbackFile: function(file) {
      impExamTestQues(file).then(res => {
        this.$toast(res.message)
        if (res.status === 200) {
          this._loadData()
        }
      })
    },

    // 收到 选择 回调
    onCallbackSelect: function(ids) {
      this.ids = ids
    },

    // 收到 table内方法 回调
    onCallbackButton: function(item, func) {
      this.ids = item.id
      this[func](item)
    },

    // 收到改变页码回调
    onCallbackPage: function(page) {
      this.formData.page = page
      this._loadData()
    },

    // 收到改变一页数量回调
    onCallbackLimit: function(limit) {
      this.formData.limit = limit
      this._loadData()
    },
  },
}
</script>

<style lang="stylus" scoped></style>
