<template>
  <el-dialog
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :destroy-on-close="true"
    :show-close="false"
    width="45%"
    :title="isEdit ? '编辑考题' : '添加考题'"
    :visible.sync="isShow"
  >
    <div class="all-input">
      <div style="width: 37%" class="item-input">
        <div class="label"><span class="red">*</span>编号：</div>
        <el-input
          placeholder="请输入编号"
          v-model="formData.serial_number"
          class="mini"
        ></el-input>
      </div>
      <div style="width: 37%" class="item-input">
        <div class="label"><span class="red">*</span>类别：</div>
        <el-select
          class="mini"
          v-model="formData.type2_id"
          placeholder="请选择"
        >
          <el-option
            v-for="item in typeArr"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div style="width: 37%" class="item-input">
        <div class="label"><span class="red">*</span>题型：</div>
        <el-select
          @change="quesChange"
          class="mini"
          v-model="formData.type"
          placeholder="请选择"
        >
          <el-option
            v-for="item in quesArr"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div style="width: 37%" class="item-input">
        <div class="label"><span class="red">*</span>分值(分)：</div>
        <el-input-number
          class="mini"
          v-model="formData.score"
          controls-position="right"
          :min="1"
        ></el-input-number>
      </div>

      <div style="width: 80% !important" class="item-input">
        <div class="label"><span class="red">*</span>题目：</div>
        <el-input
          placeholder="请输入题目"
          v-model="formData.content"
        ></el-input>
      </div>

      <div
        style="position: relative; width: 80% !important"
        class="item-input"
        v-for="(sub, index) in options"
        :key="sub.model"
      >
        <div class="label">
          <span class="red">*</span>{{ sub.name }}{{ configArr[index] }}：
        </div>
        <el-input
          :placeholder="`请输入${sub.name}${configArr[index]}内容`"
          v-model="$data[sub.model]"
        ></el-input>
        <div v-show="sub.del" @click="delOption(index)" class="del">-</div>
      </div>

      <div v-show="optionIndex !== 6" style="width: 80% !important" class="item-input">
        <div class="label"></div>
        <div @click="addOption" class="big append add hover">+</div>
      </div>

      <div style="margin-left: 60px" class="select">
        <div class="label"><span class="red">*</span>正确答案：</div>
        <div v-if="formData.type === '1'" style="margin-left: 20px">
          <el-radio
            v-model="radio"
            v-for="(sub, index) in options"
            :key="index"
            :label="configArr[index]"
            >{{ sub.name }}{{ configArr[index] }}</el-radio
          >
        </div>

        <el-checkbox-group
          style="margin-left: 20px"
          v-if="formData.type === '2'"
          v-model="checkList"
        >
          <el-checkbox
            v-for="(sub, index) in options"
            :key="sub.model"
            :label="configArr[index]"
            >{{ sub.name }}{{ configArr[index] }}</el-checkbox
          >
        </el-checkbox-group>

        <div v-if="formData.type === '3'" style="margin-left: 20px">
          <el-radio v-model="radio" label="1">正确</el-radio>
          <el-radio v-model="radio" label="0">错误</el-radio>
        </div>
      </div>
    </div>

    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit">确 定</el-button>
      <el-button @click="close">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { getETQSelect, addExamTestQues, updExamTestQues } from '@/api/safety'
export default {
  name: 'addPaper',
  props: {
    loadChange: Function,
  },
  data() {
    return {
      checkList: [],
      isShow: false, // 是否打开弹窗
      options: [], // 选项列表
      typeArr: [], // 分类列表
      quesArr: [], // 题型列表
      optionA: '',
      optionB: '',
      optionC: '',
      optionD: '',
      optionE: '',
      optionF: '', // 选项 v-model
      radio: '', // 判断 或 单选
      configArr: ['A', 'B', 'C', 'D', 'E', 'F'],
      formData: {
        type: '1', // 1单选 2多选 3 判断
        content: '', // 题目
        score: '', // 分数
        answer: '', // 答案
        serial_number: '', // 编号
        type2_id: '', // 分类
        options: '', // 选项
      },
      toastData: {
        serial_number: '请输入编号', // 编号
        type2_id: '请选择类别', // 分类
        content: '请输入题目', // 题目
        score: '请输入分值', // 分数
      },
      isEdit: false,
      optionIndex: 2, // 选项索引
      backup: [
        { name: '选项', model: 'optionA', del: false },
        { name: '选项', model: 'optionB', del: false },
      ],
      editup: {
        data: [],
        type: '',
        value: '',
      },
    }
  },
  created() {},
  mounted() {
  },
  methods: {
    _loadSelect: function (data) {
      getETQSelect().then((res) => {
        this.typeArr = res.result
        this.quesArr = [
          { id: '1', name: '单选题' },
          { id: '2', name: '多选题' },
          { id: '3', name: '判断题' },
        ]
        !data && (this.optionIndex = 2)
        !data && (this.options = JSON.parse(JSON.stringify(this.backup)))
      })
    },
    open: function (data) {
      this.isShow = true
      this.isEdit = false
      this._loadSelect(data)
      if (data) {
        // 从详情 转 对应信息
        const answer = /,/ig.test(data.answer) ? data.answer.split(',') : data.answer
        // const options = []
        if (typeof answer === 'object') this.checkList = answer
        else this.radio = answer

        if (data.type === 3) {
          this.options = []
          this.optionIndex = 6
        } else {
          this.clearOption(data.options, data.type + 1)
        }
        this.editup = {
          data: JSON.parse(JSON.stringify(data.options)),
          type: String(data.type),
          value: answer,
        }

        this.formData = data
        this.formData.answer = ''
        this.formData.options = ''
        this.formData.type = String(this.formData.type)
        this.isEdit = true
      }
    },
    close: function () {
      this.isShow = false
      this.clearFormData()
    },

    // 添加选项
    addOption: function () {
      console.log(this.optionIndex, this.configArr[this.optionIndex])
      this.options.push({
        name: '选项',
        model: `option${this.configArr[this.optionIndex]}`,
        del: true,
      })
      this.optionIndex = this.options.length
    },

    // 删除选项
    delOption: function (index) {
      if (index >= this.options.length - 1) {
        const i = this.checkList.indexOf(this.configArr[index])
        i > -1 && this.checkList.splice(i, 1)
        this['option' + this.configArr[index]] = ''
        this.options.splice(index, 1)
        this.optionIndex = this.options.length
      } else {
        this.$toast(`请先删除${this.options[index + 1].name}${this.configArr[index + 1]}`)
        return false
      }
    },

    // 清洗 选项
    clearOption: function(data, type) {
      const options = []
      data.forEach((item, index) => {
        this['option' + item.title] = item.content
        options.push({
          name: '选项',
          model: `option${item.title}`,
          del: index >= type,
        })
      })
      this.options = options
      this.optionIndex = this.options.length
    },

    // 切换题型
    quesChange: function (index) {
      if (index === '3') {
        this.options = []
        this.optionIndex = 6
        return false
      }
      const isOnly = index === '1' // '1': 单选   '2': 多选    '3': 判断
      this.optionIndex = isOnly ? 2 : 3 // 2: 单选默认为两个选项 3: 多选默认为三个选项
      const list = JSON.parse(JSON.stringify(this.backup))

      if (!isOnly) {
        list.push({ name: '选项', model: 'optionC', del: false })
      }
      this.configArr.forEach((key) => {
        this['option' + key] = ''
      })
      this.checkList = []
      this.radio = ''
      if (this.editup.type === index) {
        this.radio = this.editup.type !== '2' && this.editup.value
        this.checkList = this.editup.type === '2' && this.editup.value
        this.clearOption(this.editup.data, Number(this.editup.type) + 1)
      } else {
        this.options = list
      }
    },

    submit: function () {
      for (const key in this.toastData) {
        if (!this.formData[key]) {
          this.$toast(this.toastData[key])
          return false
        }
      }

      for (const key in this.options) {
        if (!this['option' + this.configArr[key]]) {
          this.$toast('请输入选项' + this.configArr[key] + '内容')
          return false
        }
      }

      console.log(this.checkList, this.radio, '这是神？')
      const answer = this.checkList.length ? this.checkList.join() : this.radio

      if (!answer) {
        this.$toast('请输入正确答案')
        return false
      }
      const options = []
      this.options.forEach((item, index) => {
        const curr = {
          title: this.configArr[index],
          content: this['option' + this.configArr[index]],
        }
        options.push(curr)
      })
      this.formData.options = JSON.stringify(options)
      this.formData.answer = answer

      if (!this.isEdit) this.savePaper()
      else this.editPaper()
    },

    savePaper: function () {
      addExamTestQues(this.formData).then((res) => {
        this.$toast(res.message)
        if (res.status === 200) {
          this.close(true)
          this.loadChange && this.loadChange()
        }
      })
    },

    editPaper: function () {
      updExamTestQues(this.formData).then((res) => {
        this.$toast(res.message)
        if (res.status === 200) {
          this.close(true)
          this.loadChange && this.loadChange()
        }
      })
    },

    clearFormData: function () {
      for (const key in this.formData) {
        this.formData[key] = ''
      }
      this.configArr.forEach((key) => {
        this['option' + key] = ''
      })
      this.formData.type = '1'
      this.options = []
      this.checkList = []
      // Object.assign(this.$data, this.$options.data())
      // console.log(this.$data, '这是什么')
    },
  },
}
</script>

<style lang="stylus" scoped>
.select {
  display: flex;
  align-items: center;
}

.append {
  border: 1px dashed #DCDEE2;
  border-radius: 3px;
  text-align: center;
  line-height: 26px;
  height: 30px;
  color: #DCDEE2;
  font-size: 30px;
  cursor: pointer;
}

.del {
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  right: -10%;
  background-color: #FFD2D2;
  color: #F56C6C;
  font-size: 30px;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
}
</style>
